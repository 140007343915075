/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyDTtKknDa-sNyjIAMQgN3SS6fYrjby-4dc",
  "appId": "1:1069037578874:web:ccdb4d76f049df3747a9c3",
  "authDomain": "schooldog-i-heard-ga.firebaseapp.com",
  "measurementId": "G-DNEL8HJQSX",
  "messagingSenderId": "1069037578874",
  "project": "schooldog-i-heard-ga",
  "projectId": "schooldog-i-heard-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-heard-ga.appspot.com"
}
